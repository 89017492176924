import { API } from "@project/shared";

export const AddMember = async (data): Promise<any> => {
  return API.post("/member/add", data);
};
export const UpdateMember = async (formData, id): Promise<any> => {
  return API.put(`/member/update/profile/${id}`, formData);
};
export const GetMemberInformation = async ({ queryKey }) => {
  const id = queryKey[1];
  return API.get(`/member/profile/${id}`);
};
export const ValidateInvitation = async (data): Promise<any> => {
  return API.post(`/invitation/validate`, { invitationCode: data.validate });
};
export const saveSignUp = async (formData): Promise<any> => {
  return API.post(`/members/sign-up`, formData);
};
export const CorporateSignUP = async (formData) => {
  return API.post("/corporate/sign-up", formData);
};

export const GetUserInfo = async (id) => {
  return API.post(`/get-user-details`, {
    uuid: id,
    role: "member",
  });
};

export const ChangeSBEmail = async (email) => {
  return API.post("member/change-email", {
    email: email,
  });
};

export const UpdateLastUsageAt = async () => {
  return API.post("member/set-last-usage");
};

export const GetUserIDFromUUID = async (uid: string) => {
  return API.get(`/member/get-members-id?uid=${uid}`);
};
