import { createContext, useState, useEffect } from "react";
import { firebase } from "@project/shared";
import { useMutation, useQuery } from "react-query";
import { GetUserInfo, UpdateLastUsageAt } from "../services/sbMembers";
import { useRouter } from "next/router";
import moment from "moment";

type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  authenticated: any;
  userId: string;
  setUser: any;
  profile: any;
  setProfile: any;
  setUsername: any;
  username: any;
  rank: any;
  setRank: any;
  messageCount: any;
  setMessageCount: any;
  refetchUserInfo: () => void;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null as firebase.User | null);
  const [profile, setProfile] = useState("");
  const [rank, setRank] = useState("");
  const [username, setUsername] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(null as boolean | null);
  const [userId, setUserId] = useState("");
  const [messageCount, setMessageCount] = useState("");
  const router = useRouter();

  const { data: userInfo, refetch: refetchUserInfo } = useQuery(
    "fetch-header",
    () => GetUserInfo(user?.uid),
    {
      refetchOnWindowFocus: true,
      enabled: user && user?.emailVerified ? true : false,
    }
  );
  useEffect(() => {
    if (userInfo) {
      setProfile(userInfo?.data?.profileImageUrl);
      setUsername(userInfo?.data?.username);
      setRank(userInfo?.data?.rank);
      setMessageCount(userInfo?.data?.unreadMessageCount);
    }
  }, [userInfo]);
  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      setUser(user);
      setAuthenticated(user !== null);
      setUserId(user?.uid);
    });

    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      const today = moment().format("YYYY-MM-DD");
      const lastUsageAt = localStorage.getItem("lastUsageAt");
      if (lastUsageAt === null || lastUsageAt !== today) {
        usageMutation();
      }
    }
  }, [router.pathname, user]);

  const { mutate: usageMutation } = useMutation(UpdateLastUsageAt, {
    onSuccess: (response) => {
      localStorage.setItem(
        "lastUsageAt",
        moment(response?.data?.lastUsageAt).format("YYYY-MM-DD")
      );
    },
  });

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated,
        setUser,
        profile,
        setProfile,
        username,
        setUsername,
        rank,
        setRank,
        userId,
        messageCount,
        refetchUserInfo,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
